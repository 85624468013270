import React from 'react';
import { Link } from 'react-router-dom';
// const Logo = require("../logo.svg");
// import { auth } from '../lib/firebase/index';
// import MenuAdmin from './MenuAdmin';

function Header( props ) {

  let update = props.update;

  return (

    <header className='page-header ombrage'>
       
       <Link className="navbar-brand ml-3" to="/" onClick={ update } >  
          <img src="logo.svg" alt="Logo" className="ms-2" />
        </Link>

    </header>

  );
}

export default Header;