const locale = 'fr-CA';

export function formatHeure( date ){

    const options = {

        hour: 'numeric',
        minute: '2-digit'
        
    }

    return date.toLocaleTimeString( locale, options );

}


export function formatDate( date ){

    const options = {

        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'

    };

    return date.toLocaleDateString( locale, options);

}