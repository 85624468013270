import React, { Component } from "react";

class Loading extends Component {
    state = {  }

    render() { 
        return (
            <div>
                <div style={{width: 5 + "%", minWidth: 40 + "px"}} className="text-center m-auto">
                    <svg id="pacman" viewBox="0 0 100 100">
                        <circle cx="50%" cy="50%" r="25%" />
                    </svg>
                    
                </div>
                <div className="text-center m-auto">
                    {/* <p className="text-light"><small>Chargement des projets...</small></p> */}
                </div>
            </div>
        )
    }
}
 
export default Loading;