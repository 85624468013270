import { formatHeure, formatDate } from '../lib/func/formatHeure';
import { Supprimer } from '../components/Supprimer';


export const Modal = ( info ) => {

    const modal = info.reservation;
    const local = info.locaux.find( e => Number(e.id) === Number(modal.local) );
    const updateRes = info.updateRes;

    const handleModalClickClose = (e) => {
        if( e.target.id === "modal" ){
            closeModal();
        }
    }

    const closeModal = () => {

        window.removeEventListener( "click", handleModalClickClose);

        document.getElementById("modal").classList.remove( "modal-show");
        document.getElementById("modal").classList.add( "modal-hide");

        setTimeout( ()=>{info.closeModal()}, 200)
        
    }

    const updateReservations = () => {
        updateRes();
    }

    //*************************************************
    //************  Format des heures   ***************
    //*************************************************
    
    let debut = new Date( modal.debut );
    let fin   = new Date( modal.fin   ); 
    
    let heure = formatHeure( debut );
    let heureFin = formatHeure( fin );
 
    //*************************************************


    //*************************************************
    //************  Format des dates   ***************
    //*************************************************
    
    let dateDebut = formatDate( debut );
    let dateFin   = formatDate( fin );
 
    //*************************************************



    //*************************************************
    //**********    Format du nom    ******************
    //*************************************************
    
    let nomComp = modal.qui.split(".");
    let prenom, nom;
    if( nomComp.length > 1){
        prenom = nomComp[0][0].toUpperCase() + nomComp[0].slice(1);
        nom = nomComp[1][0].toUpperCase() + nomComp[1].slice(1);
    }

    //*************************************************
    
    
    
    //*************************************************
    // ** Calcule la position du modal selon l'écran **
    //*************************************************
    
    let posX, posY;

    if( modal.clientX + 150 > window.innerWidth ){
        posX = window.innerWidth - 300;
    } else 
    if( modal.clientX - 150 < 0 ){
        posX = 0;
    } else
    {
        posX = modal.clientX - 150;
    }
    
    if( modal.clientY + 300 > window.innerHeight ){
        posY = window.innerHeight - 300;
    } else 
    if( modal.clientY - 60 < 0 ){
        posY = 0;
    }else {
        posY = modal.clientY -60;
    }

    // //***************************************************************

    modal.qui = `${prenom} ${nom}`;
    modal.posX = posX;
    modal.posY = posY;
    modal.debut = heure;
    modal.fin = heureFin;
    modal.local = local.nom;
    modal.serie = modal.reserveID;
    modal.qui = `${prenom} ${nom}`;
    


    setTimeout( ()=>{
        document.getElementById("modal").classList.remove( "modal-hide");
        document.getElementById("modal").classList.add( "modal-show");
    }, 50);

    window.addEventListener( "click", handleModalClickClose);
  

    return (     
        <div id="modal" className="modal modal-hide" tabIndex="-1" role="dialog" style={{ color:"darkgray" }}>
            
            <div className="modal-dialog" role="document" style={{
                    top: modal.posY, 
                    left: modal.posX
                }}>

                <div className="modal-content">

                    <div className="modal-header">
                        <span className="material-symbols-outlined ml-2" onClick={closeModal} style={{float: "right", cursor: "pointer", color: "white", fontSize: "22px", verticalAlign: "bottom"}}>
                            close
                        </span>
                        <h5 className="modal-title">{modal.local}</h5>
                
                        
                    </div>

                    <div className="modal-body">
                        <p className='mb-0'>{modal.qui}</p>
                        <p className='mb-0'>{dateDebut}</p>
                        <p className='mt-0'>{modal.debut} à {modal.fin}</p>
                    </div>
                    <div className='text-center pb-3'>
                        <Supprimer id={modal.id} update={updateReservations} serie={false}/>
                        { modal.serie &&
                            <Supprimer id={modal.id} update={updateReservations} serie={modal.reserveID}/>
                        }
                        {/* <Modifier  id={modal.id} update={getReservations}/> */}
                    </div>
                </div>

            </div>

        </div>
    )

}
