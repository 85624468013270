import CheckBox from "./CheckBox";
import React, { useState, useEffect } from 'react';

const ListeLocaux = ({ locaux, user, dateDebut, dateFin, update }) => {

  let locauxOrdre = [[]];
  let c = -1;
  let cu = 0;
  let prevcu = -1;
  let liste = [];

  locaux.forEach( l => {

    if( l.actif == 0 ) return;

    if( prevcu < Math.floor(Number(l.nom) / 100 )) {
      cu = Math.floor(Number(l.nom) / 100 );
    }
    
    if(isNaN(Number(l.nom)) && prevcu != null){
      prevcu = null;
      c++;
      locauxOrdre[c] = [];
    } 
    else if( cu !== prevcu && prevcu != null ){
      c++;
      locauxOrdre[c] = [];
    
      prevcu = cu;
    } 

    locauxOrdre[c].push(l)
  });

  let prevLocaux = locauxOrdre;

  const [ locauxSelectionner, setLocauxSelectionner ] = useState([]);
  // const [ tousLocaux, setTousLocaux ] = useState( locauxOrdre );
  // const [ locauxDisponible, setLocauxDisponible ] = useState( disponible );

  // const [prevLocaux, setPrevLocaux] = useState(locauxOrdre);
  // const [prevDisponible, setPrevDisponible] = useState(disponible);

  let compteur = 10;
  let prevCompteur = 0;
  let titre = "";


  useEffect( () => {

    update( locauxSelectionner );

  }, [locauxSelectionner, update]);


  const chkEn = local => {

    return ( local.permission && local.disponible && !local.reserver );

  }

  const handleCheck = ( valeur ) => {
    
    if( locauxSelectionner.includes( valeur ) ){
      setLocauxSelectionner( locauxSelectionner.filter( id => id !== valeur ))
    } else {
      setLocauxSelectionner( [...locauxSelectionner, valeur ] );
    }

  }

  if( locauxOrdre && locauxOrdre.length > 0  ){
   
    locauxOrdre.forEach( (c, i) => {
    
      if( c.length <= 0 ) return false;
      
      liste[i] = (

        <div className="col-sm text-center p-3 m-2 glass" key={c[0].nom}>
          
          <h2 className="mb-4" style={{borderBottom: "1px solid #777"}}>{ !isNaN( Number(c[0].nom) ) ? c[0].nom.substring(0,2) + ".." : "Autres" }</h2>

            

              {c.map( l => {

                return <CheckBox 
                          update={handleCheck} 
                          valeurProp={l.id} 
                          messageProp={l.nom} 
                          disponible={ l.disponible } 
                          reserver={l.reserver} 
                          qui={ l.qui } 
                          permission={ l.permission } 
                          enabledProp={ chkEn(l) }
                          key={l.id}
                        />;
            
              })}

    

        </div>
        )
      

    })

    
  
  }
  

  return (
    
    <div className="container">
      <div className="row text-center">
        {liste.map( l => {
          return l;
        })}
      </div>
      
    </div>

  )
  
}

export default ListeLocaux;