import { useNavigate } from "react-router-dom"


export const Retour = () => {   

    const navigate = useNavigate();

    return (
        <button className='btn btn-primary mb-2 mt-2' onClick={()=>navigate(-1)} >
            <span className="material-symbols-outlined mr-2" style={{fontSize: "22px", verticalAlign: "bottom"}}>
                west
            </span>
            Retour
        </button>
    )
    
}