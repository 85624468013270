import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { HOST } from '../lib/vars/host';
import ListeLocaux from '../components/ListeLocaux';
import {useLocation, useNavigate} from 'react-router-dom';
import Loading from '../components/Loading';

export default function Locaux() {

    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = JSON.parse( location.state.user );
  
    const [ dateDebut, setDateDebut ] = useState( location.state.debut );
    const [ dateFin, setDateFin ] = useState( location.state.fin );
    const [ finDeSemaine, setFinDeSemaine ] = useState( location.state.fs );
    const [ jourDeSemaine, setJourDeSemaine ] = useState( location.state.jourDeSemaine );
    const [ periode, setPeriode ] = useState( location.state.periode );
    const [ erreur, setErreur ] = useState();
    const [ locaux, setLocaux ] = useState([]);
    const [ locauxSelectionner, setLocauxSelectionner ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    if( locaux.length === 0 ){
        const options = {
            method: 'post',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                user: userInfo.id,
                debut: dateDebut,
                fin: dateFin
            })
        }

        fetch(`${HOST}/getLocauxDisponible`, options)
        .then(response => response.json() )
        .then(data =>{
            
            let locaux = data.data;
            locaux.sort( (a, b) => a.nom.localeCompare(b.nom) )
    
            setLocaux( locaux );
            setLoading( false )
        });
    }


    const setLocauxSelect = ( locauxSelectionner ) => {

        setLocauxSelectionner( locauxSelectionner );

    }

  
    const suivant =  () => {

        setLoading(true);

        if( locauxSelectionner.length <= 0 ){
            window.scrollTo(0, 0);
            setErreur( "Tu dois sélectionner un local. 🤔");
            setLoading( false );
            return;
        }

        const options = {
            method: 'post',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(
                { info :{
                        debut: dateDebut,
                        fin: dateFin,
                        local: locauxSelectionner,
                        user: userInfo.id,
                        fs: finDeSemaine, //true ou false
                        periode: periode, 
                        js: jourDeSemaine
                    }
                }
            )
        }
   

        fetch(`${HOST}/setReservation`, options)
        .then(response => response.json() )
        .then(data =>{
            
            navigate( '/confirmation', { state: {debut: dateDebut, fin: dateFin, local: locauxSelectionner, succes: data, periode: periode, jourDeSemaine: jourDeSemaine } });

        });
            
    }

    if(loading){
        return (

            <div className="text-light">
    
                <Header />

                <section>
                   
                    <div className="arr-plan">
                        <Loading />
                    </div>

                </section>

                <Footer />

            </div>
        )
    }

    return (
            <div className="text-light">
    
                <Header />

                <section>
                   
                    <div className="arr-plan">

                        <div  className="glass">
                        
                        <div className='d-flex justify-content-between'>

                            <button className='btn btn-primary' onClick={()=>navigate(-1)} >
                                <span className="material-symbols-outlined mr-2" style={{fontSize: "22px", verticalAlign: "bottom"}}>
                                    west
                                </span>
                                Retour
                            </button>

                            <button className='btn btn-primary' onClick={suivant}>
                                Suivant
                                <span className="material-symbols-outlined ml-2" style={{fontSize: "22px", verticalAlign: "bottom"}}>
                                    east
                                </span>
                            </button>
                        </div>

                            {/**************************** ERREUR ************************** */}
                            { erreur && erreur.length > 0 &&
                            
                                <div className='d-flex flex-row-reverse mr-5 mt-1' style={{flexWrap:"nowrap"}}>
                                    
                                    <span className='alert alert-danger'>{erreur}</span>

                                </div>
                            }

                            
                            <ListeLocaux locaux={ locaux } user={ userInfo } dateDebut={ dateDebut } dateFin={ dateFin } update={ setLocauxSelect } />
                            
                            <div className='d-flex justify-content-between'>

                                <button className='btn btn-primary' onClick={()=>navigate(-1)} >
                                    <span className="material-symbols-outlined mr-2" style={{fontSize: "22px", verticalAlign: "bottom"}}>
                                        west
                                    </span>
                                    Retour
                                </button>

                                <button className='btn btn-primary' onClick={suivant}>
                                    Suivant
                                    <span className="material-symbols-outlined ml-2" style={{fontSize: "22px", verticalAlign: "bottom"}}>
                                        east
                                    </span>
                                </button>
                            </div>
                            
                        </div>
                            
                    </div>
                    
                </section>

                <Footer />

            </div>
        )
    
}