export default function horaireDate( date ){

    const horaires = [
        null,
        "A,B,C,Diner,D,PAAQ,E",
        "A,B,C,Diner,D,PAAQ,E",
        "A,B,C,Diner,D,PAAQ,E",
        "A,B,C,Diner,D,PAAQ,E",
        "A,B,C,Diner,D,PAAQ,E",
        null
    ]

    const heures = [
        { d: {h:  9, m:  0}, f: { h:  10, m: 0 } }, // Période A
        { d: {h:  10, m: 5}, f: { h: 11, m:  5 } }, // Période B
        { d: {h: 11, m: 15}, f: { h: 12, m: 15 } }, // Période C
        { d: {h: 12, m: 15}, f: { h: 13, m: 15 } }, // Diner
        { d: {h: 13, m: 15}, f: { h: 14, m: 15 } }, // Période D
        { d: {h: 14, m: 20}, f: { h: 14, m: 50 } }, // PAAQ
        { d: {h: 15, m:  0}, f: { h: 16, m:  0 } }  // Période E
    ]
   
    const jour = date.getDay();
    let horaire = horaires[ jour ];

    if( horaire ){
        horaire = horaire.split(",");
    } else {
        horaire = [];
    }

    const info = { horaire: horaire, heures: heures };

    return info;

}