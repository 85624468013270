import React, { Component } from 'react';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import startOfWeek from 'date-fns/startOfWeek';
import startOfToday from 'date-fns/startOfToday';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import addDays from 'date-fns/addDays';
import endOfMonth from 'date-fns/endOfMonth';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import format from 'date-fns/format';
import { frCA } from 'date-fns/locale';
import {HOST} from '../lib/vars/host'
// import { parseDateEvent } from '../../../../../../Users/Labos Créatifs/OneDrive/Desktop/inscriptions.odytek.com/src/helpers/date';
// import { getEvents } from '../../../../../../Users/Labos Créatifs/OneDrive/Desktop/inscriptions.odytek.com/src/helpers/db';
import { isWeekend } from 'date-fns';
import { isAfter } from 'date-fns/esm';
import Loading from './Loading';
// import Status from '../../../../../../Users/Labos Créatifs/OneDrive/Desktop/inscriptions.odytek.com/src/components/Status';


export default class Calendrier extends Component {

    constructor(props){
        super(props);

        let local = props.local;

        
        this.state = {
            currentMonth: new Date(),
            selectedDate: new Date(),
            reservations: [],
            update: props.update || null,
            loading: true,
            erreur: null
        };

    }

    componentDidMount() { 
      
      const options = {
        method: 'post',
        headers: new Headers({
            'Content-Type': 'application/json'
        })
    }

    fetch(`${HOST}/getReservations`, options)
    .then(response => response.json() )
    .then(data =>{
        
        let reserv = data.data;
        reserv.sort( (a, b) => a.debut.localeCompare(b.debut) )
       
        this.setState({
          reservations: reserv,
          loading: false})

    })
    .catch( err => {
      this.setState({
        erreur: "aucune connection, désolé 😒",
        loading: false})
    })
    
    }
    
    renderHeader() {

        const dateFormat = "MMMM y";
    
        return (
          <div className="headerCalendar rowCalendar flex-middle text-light">

            <span className="material-symbols-outlined cal-title" style={ { cursor: 'pointer', padding: "5px", paddingLeft: "10px" } } onClick={this.prevMonth}>
    
              arrow_back_ios

            </span>
             
            <div className="colCalendar col-centerCalendar cal-title">
              <span>{ format( this.state.currentMonth, dateFormat, { locale: frCA} ) }</span>
            </div>

            <span className="material-symbols-outlined  cal-title" style={ { cursor: 'pointer', padding: "5px", paddingLeft: "10px" } } onClick={this.nextMonth}>
      
              arrow_forward_ios

            </span>

          </div>
        );
    }
    
      renderDays() {

        const dateFormat = "E";
        const days = [];
    
        let startDate = startOfWeek(this.state.currentMonth);
    
        for (let i = 0; i < 7; i++) {
          days.push(
            <div className="colCalendar col-centerCalendar" key={i}>
              {format(addDays(startDate, i), dateFormat, {locale: frCA})}
            </div>
          );
        }
    
        return <div className="days rowCalendar">{days}</div>;
      }
    
      renderCells() {
        const { currentMonth, selectedDate } = this.state;
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
    
        const dateFormat = "d";
        const rows = [];
    
        let days = [];
        let day = startDate;
        
        let formattedDate = "";
    
        while (day <= endDate) {

          for (let i = 0; i < 7; i++) {

            formattedDate = format(day, dateFormat);

            const cloneDay = day;
           
            let found = this.state.reservations.some( (e) => { 

              let debut = new Date(e.debut);
              debut.setHours(0, 0, 0);;

              let fin = new Date(e.fin);
              fin.setHours(0, 0, 0);

              return debut <= cloneDay && fin >= cloneDay;

            } );
            
           
            days.push(
              
              <div
                className={`colCalendar cell ${ !found || found.length <= 0 ? "disabled" : "" }
                      ${isSameDay(day, selectedDate) ? "selected" : ""
                  } ${ 
                    isWeekend( day ) || !isSameMonth(day, monthStart) 
                      ? "weekend" : "" }`
                }
                key={day}
                onClick={() => this.onDateClick(cloneDay)}
              >
                  {/* EVENTS********************************************************* */}
                <span className={`number ${found ? "events" : "disabled"}`}>{formattedDate}</span>
                <span className="bg">{formattedDate}</span>
              </div>
            );
            day = addDays(day, 1);
          }
          rows.push(
            <div className="rowCalendar" key={day}>
              {days}
            </div>
          );
          days = [];
        }
        return <div className="body">{rows}</div>;
      }
    
      onDateClick = day => {
        this.state.update( day );
      };
    
      nextMonth = () => {
        this.setState({
          currentMonth: addMonths(this.state.currentMonth, 1)
        });
      };
    
      prevMonth = () => {
        this.setState({
          currentMonth: subMonths(this.state.currentMonth, 1)
        });
      };
    
      render() {

        // if( this.state.loading){
        //   return <Loading />
        // }

        return (
          <div>
            {/* <Status calendrier="true" labo={this.state.local.indexOf("Labo") !== -1} /> */}
            { this.state.loading ? 
                <Loading /> :
              
              this.state.erreur ?
                <div className='alert alert-danger m-5'>{this.state.erreur}</div> :

                <div className="calendar m-3">
                  {this.renderHeader()}
                  {this.renderDays()}
                  {this.renderCells()}
                </div>
              }
            

          </div>
        );
      }


}