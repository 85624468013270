import { useState } from "react"
import { HOST } from "../lib/vars/host"
import Loading from "./Loading";


export const Supprimer = ( {id, update, serie} ) => {   

    const [ clicked, setClicked ] = useState(false);
    const [ succes,  setSucces  ] = useState(false);
    const [ failed,  setFailed  ] = useState(false);
    const [ loading, setLoading ] = useState(false);

        
    const supprimerReservation = ( id, pass ) => {

        setLoading(true);

        let url = `${HOST}/supprimer`;
        let reserveID = null;

        if( serie ){
            url = `${HOST}/supprimerSerie`;
            reserveID = serie;
        }


        const options = {
            method: 'post',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
            data: { id:id, pass: pass, reserveID: reserveID }
            })
        }

        fetch( url, options)
        .then(response => response.json() )
        .then(data =>{

            setLoading(false);
            
            if( !data.failed ){
                setSucces(true);
                setTimeout( update, 2000 );
            } else {
                if( data.erreur ) {
                    setFailed( data.erreur );   
                } else {
                    setFailed( true );
                } 
            }
        })
        .catch( err => {
            console.log(err)
        } )
    }
        
    
    const handleClicked = () => {
        if( !clicked ){
            setClicked( true );
        }
    }



    return (
        <button data-toggle="tooltip" title= { serie? "Supprimer la série":"Supprimer cette réservation" } className={'btn btn-primary p-2 btn-supprimer ' + (serie?"ml-4":"") } onClick={handleClicked} style={{ width: clicked ? "160px" : "60px", cursor: clicked ? "default" : "pointer"}} >
            
            {!clicked && !succes && !loading && !failed &&
                <>
                    <span className="material-symbols-outlined" style={{cursor: "pointer", fontSize: "35px", verticalAlign: "bottom"}}>
                        {serie ? "date_range" : "today" }
                    </span>
                    
                    <span className="material-symbols-outlined" style={{cursor: "pointer", fontSize: "20px", verticalAlign: "bottom"}}>
                        delete
                    </span>
                </>
            }


            { clicked && !succes && !loading && !failed &&
                <>  
                    <input 
                        id="pass" 
                        className="form-control" 
                        type="password" 
                        maxLength="15" 
                        placeholder="Entre le code"
                        style={{maxWidth: "140px"}} 
                        onKeyDown={e => e.key === 'Enter' && supprimerReservation( id, document.getElementById("pass").value ) }
                        autoFocus
                        ></input>
                    <span id="supprime" className="material-symbols-outlined " onClick={ ()=>{ supprimerReservation( id, document.getElementById("pass").value )} } style={{fontSize: "22px", verticalAlign: "middle", cursor: "pointer"}}>
                        arrow_forward
                    </span>
                </>
            }
            {
                succes && <>
                <span className="material-symbols-outlined" style={{fontSize: "25px", verticalAlign: "middle"}} >
                    done
                </span>
                <span className="ml-2"> effacé</span></>
            }
            {
                failed && failed === true ? <>
                    <span className="material-symbols-outlined" style={{fontSize: "25px", verticalAlign: "middle"}}>
                            cancel
                    </span>
                    <span className="ml-2">pas réussi</span>
                </>
                : failed && <>
                    <span className="material-symbols-outlined" style={{fontSize: "25px", verticalAlign: "middle"}}>
                            cancel
                    </span>
                    <span className="ml-2">{failed}</span>
                </>
            }
            {
                loading &&
                    <Loading />
            }
        </button>
    )
    
}