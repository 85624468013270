import React, { useState } from 'react';

export default function CheckBox( { messageProp, valeurProp, update, enabledProp, disponible, permission, reserver, qui } ) {

  const [ texte,    setTexte    ] = useState(messageProp);
  const [ valeur,   setValeur   ] = useState(valeurProp);
  // const [ prevData, setPrevData ] = useState(message);
  const [ enabled,  setEnabled  ] = useState(enabledProp);
  const [ checked,  setChecked  ] = useState( false );
  const [ showPopup,  setShowPopup  ] = useState( false );

  let custCSS = "";
  let erreur = null;

  
  if( !disponible ) {
    custCSS = " disponible";
    erreur = "Ce local n'est pas disponible à cette date et heure.";
  }

  if( !permission ) {
    custCSS = " permission";
    erreur = "Désolé, tu n'as pas la permission de réserver ce local.";
  }

  if( reserver ){
    custCSS = " reserver";
    erreur = `Ce local est déjà réservé par ${qui} cette date et heure.`;
  }

  const handleClick = (e) => {

    update( valeur, !checked );
    setChecked(!checked)    
  
  }
  
  return (
    <div className="text-center mb-1 " key={"filtreDivContainer" + texte} style={{cursor:"pointer", zIndex: "1"}}>

      <div 
        className="custom-control custom-checkbox text-left" key={"filtreDiv" + texte}
        data-toggle="tooltip"
        title= {erreur}
        style={{cursor:"pointer"}}
      >
        
        <input style={{cursor:"pointer"}} className={"custom-control-input"} onChange={handleClick} type="checkbox" value={valeur} id={"filtre" + texte} key={"filtreInput" + texte} checked={checked} disabled={!enabled} />

        <label style={{cursor:"pointer"}} className={"custom-control-label" + custCSS} key={"filtreLabel" + texte} htmlFor={"filtre" + texte} >
          {texte}
        </label>

      </div> 
      
    </div>
  )
   
}